import { useStaticQuery, graphql } from 'gatsby'

export const QueryHome = () => {
  const query = useStaticQuery(
    graphql`
      {
        sanityHome {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `
  )
  return JSON.parse(query.sanityHome.internal.content)
}
