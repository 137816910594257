import React from 'react'
import cx from 'classnames'

import store from '../state/store.js'
import META from '@/meta.js'
import Footer from '@/footer.js'
import { renderModules } from '@/utls.js'
import { QueryHome } from '../utls/queryHome.js'
import { Projects, ArrowExternal } from '@/svgs.js'

import HeroCarousel from '@/modules/heroCarousel.js'

const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.add('opened')
}

const Home = ({ transitionStatus, entry, exit }) => {
  const data = QueryHome()
  return (
    <div className={cx('animate__page homePage', transitionStatus)}>
      <META data={data} />
      <div className='nav__push home' />
      <div className='container--xl ma x p1 projectNavContain'>
        <div className='f jcs aic work__landing-nav' onClick={updateStates}>
          <Projects /><h4 className='p0 m0 mono h5 caps'>Projects</h4>
        </div>
      </div>
      <HeroCarousel carousel={data.featuredCaseStudies} />
      {renderModules(data.modules)}
      <Footer />
    </div>
  )
}

export default Home
