import React from 'react'
import Image from '../image.js'
import PageLink from '../link.js'

import { Arrow } from '../svgs.js'

class HeroCarousel extends React.Component {
  constructor () {
    super()
    this.state = {
      activeSlide: 1
    }
    this.slideshow = React.createRef()
    this.rightArrow = React.createRef()
    this.leftArrow = React.createRef()
  }
  componentDidMount () {
    console.log('props', this.props)
    const Flickity = require('flickity')
    const slideshow = this.slideshow.current
    const flky = new Flickity(slideshow, {
      contain: true,
      wrapAround: true,
      pageDots: false,
      bgLazyLoad: 2,
      cellAlign: 'center',
      autoPlay: 6000,
      prevNextButtons: false
    })

    const left = this.leftArrow.current
    const right = this.rightArrow.current

    left.addEventListener('click', () => flky.previous())
    right.addEventListener('click', () => flky.next())

    flky.on('select', (index) => this.setState({ activeSlide: index + 1 }))

    setTimeout(() => {
      flky.resize()
    }, 100)
  }
  render () {
    const {
      slide
    } = this.props.carousel
    return (
      <div className='x cw rel z2 carousel__wrapper hero__wrapper'>
        <div className='y abs x f jcc aic'>
          <div className='container--xl y hero ma p1 x abs'>
            <div ref={this.leftArrow} className='slide__controls slide__controls-left abs z1 left'>
              <Arrow />
            </div>
            <div ref={this.rightArrow} className='slide__controls slide__controls-right abs z1 right'>
              <Arrow />
            </div>
            <div className='abs z2 right bottom slide__counter p1 h3'>{this.state.activeSlide}/{slide.length}</div>
          </div>
        </div>
        <div ref={this.slideshow} className='hero__carousel'>
          {slide.map(sli => (
            <div className='x hero__carousel-slide' key={sli._key}>
              <PageLink className='abs z2 x y' to={`/projects/${sli.linkedProject.slug.current}`} />
              <div className='abs x y f jcc z1 aie'>
                <div className='container--xl x hero__carousel-text p1'>
                  <h3 className='m0 p0'>{sli.linkedProject.clientName}</h3>
                  <h3 className='m0 p0 italic'>{sli.linkedProject.title}</h3>
                </div>
              </div>
              <Image timeOut={800} className='x y' alt='' imageId={sli.imageId} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default HeroCarousel
